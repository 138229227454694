import React from 'react';
import 'react-activity/dist/react-activity.css';
import classNames from 'classnames';
import Loader from 'src/components/shared/Loader';

import * as Styles from 'src/styles/questionnaire-transition-page.module.scss';
import Colors from 'src/utils/colors';

interface TransitionPageProps {
    message?: string;
}

const TransitionPage = ({ message }: TransitionPageProps) => {
    return (
        <main
            aria-label="main"
            className={classNames(
                Styles.transitionPage,
                'display-column justify-content--center align-items--center'
            )}
        >
            <Loader spinnerColor={Colors.black}>
                <h1 aria-live="polite" className="accent">
                    {message}
                </h1>
            </Loader>
        </main>
    );
};

export default TransitionPage;
