// extracted by mini-css-extract-plugin
export const colorPvSea = "questionnaire-transition-page-module--color-pv-sea--3FitR";
export const bgcolorPvSea = "questionnaire-transition-page-module--bgcolor-pv-sea--2unP6";
export const borderColorPvSea = "questionnaire-transition-page-module--border-color-pv-sea--1PKzG";
export const colorPvForest = "questionnaire-transition-page-module--color-pv-forest--1hE4y";
export const bgcolorPvForest = "questionnaire-transition-page-module--bgcolor-pv-forest--316cZ";
export const borderColorPvForest = "questionnaire-transition-page-module--border-color-pv-forest--309m8";
export const colorPvDesert = "questionnaire-transition-page-module--color-pv-desert--avlH4";
export const bgcolorPvDesert = "questionnaire-transition-page-module--bgcolor-pv-desert--3Etm-";
export const borderColorPvDesert = "questionnaire-transition-page-module--border-color-pv-desert--3_5ed";
export const colorPvBone = "questionnaire-transition-page-module--color-pv-bone--2Djll";
export const bgcolorPvBone = "questionnaire-transition-page-module--bgcolor-pv-bone--2j88n";
export const borderColorPvBone = "questionnaire-transition-page-module--border-color-pv-bone--Ea3Q4";
export const colorPvMud = "questionnaire-transition-page-module--color-pv-mud--QLuVV";
export const bgcolorPvMud = "questionnaire-transition-page-module--bgcolor-pv-mud--m8BFC";
export const borderColorPvMud = "questionnaire-transition-page-module--border-color-pv-mud--3L_Sq";
export const colorPvDune = "questionnaire-transition-page-module--color-pv-dune--1iF9T";
export const bgcolorPvDune = "questionnaire-transition-page-module--bgcolor-pv-dune--Sv5ux";
export const borderColorPvDune = "questionnaire-transition-page-module--border-color-pv-dune--26fyq";
export const colorPvWhite = "questionnaire-transition-page-module--color-pv-white--1wqxr";
export const bgcolorPvWhite = "questionnaire-transition-page-module--bgcolor-pv-white--6RaG9";
export const borderColorPvWhite = "questionnaire-transition-page-module--border-color-pv-white--JOP6p";
export const colorPvGray10 = "questionnaire-transition-page-module--color-pv-gray-10--excVq";
export const bgcolorPvGray10 = "questionnaire-transition-page-module--bgcolor-pv-gray-10--27l-i";
export const borderColorPvGray10 = "questionnaire-transition-page-module--border-color-pv-gray-10--1GHMF";
export const colorPvGray30 = "questionnaire-transition-page-module--color-pv-gray-30--1FpB7";
export const bgcolorPvGray30 = "questionnaire-transition-page-module--bgcolor-pv-gray-30--2psz1";
export const borderColorPvGray30 = "questionnaire-transition-page-module--border-color-pv-gray-30--2wM3j";
export const colorPvGray50 = "questionnaire-transition-page-module--color-pv-gray-50--HGVRS";
export const bgcolorPvGray50 = "questionnaire-transition-page-module--bgcolor-pv-gray-50--2hqIc";
export const borderColorPvGray50 = "questionnaire-transition-page-module--border-color-pv-gray-50---K9qj";
export const colorPvGray70 = "questionnaire-transition-page-module--color-pv-gray-70--2M10q";
export const bgcolorPvGray70 = "questionnaire-transition-page-module--bgcolor-pv-gray-70--234d9";
export const borderColorPvGray70 = "questionnaire-transition-page-module--border-color-pv-gray-70--2Fp1I";
export const colorPvGray90 = "questionnaire-transition-page-module--color-pv-gray-90--3zwSi";
export const bgcolorPvGray90 = "questionnaire-transition-page-module--bgcolor-pv-gray-90--3bojB";
export const borderColorPvGray90 = "questionnaire-transition-page-module--border-color-pv-gray-90--3LrNe";
export const colorPvBlack = "questionnaire-transition-page-module--color-pv-black--1DMik";
export const bgcolorPvBlack = "questionnaire-transition-page-module--bgcolor-pv-black--101fW";
export const borderColorPvBlack = "questionnaire-transition-page-module--border-color-pv-black--10-IH";
export const colorPvMint = "questionnaire-transition-page-module--color-pv-mint--3MJTu";
export const bgcolorPvMint = "questionnaire-transition-page-module--bgcolor-pv-mint--bMFRx";
export const borderColorPvMint = "questionnaire-transition-page-module--border-color-pv-mint--2ndQv";
export const colorPvLightMint = "questionnaire-transition-page-module--color-pv-light-mint--1qQq6";
export const bgcolorPvLightMint = "questionnaire-transition-page-module--bgcolor-pv-light-mint--1v-B_";
export const borderColorPvLightMint = "questionnaire-transition-page-module--border-color-pv-light-mint--2M4k3";
export const colorPvPink = "questionnaire-transition-page-module--color-pv-pink--xuTh1";
export const bgcolorPvPink = "questionnaire-transition-page-module--bgcolor-pv-pink--27cnH";
export const borderColorPvPink = "questionnaire-transition-page-module--border-color-pv-pink--3DIOb";
export const colorPvLightPink = "questionnaire-transition-page-module--color-pv-light-pink--G4a1V";
export const bgcolorPvLightPink = "questionnaire-transition-page-module--bgcolor-pv-light-pink--1R0i9";
export const borderColorPvLightPink = "questionnaire-transition-page-module--border-color-pv-light-pink--JSQu6";
export const colorPvGold = "questionnaire-transition-page-module--color-pv-gold--2LzSi";
export const bgcolorPvGold = "questionnaire-transition-page-module--bgcolor-pv-gold--2jy6n";
export const borderColorPvGold = "questionnaire-transition-page-module--border-color-pv-gold--3wkS9";
export const colorPvLightGold = "questionnaire-transition-page-module--color-pv-light-gold--2tBlU";
export const bgcolorPvLightGold = "questionnaire-transition-page-module--bgcolor-pv-light-gold--37gk6";
export const borderColorPvLightGold = "questionnaire-transition-page-module--border-color-pv-light-gold--18iyX";
export const colorPvLime = "questionnaire-transition-page-module--color-pv-lime--1MXlY";
export const bgcolorPvLime = "questionnaire-transition-page-module--bgcolor-pv-lime--1ALfR";
export const borderColorPvLime = "questionnaire-transition-page-module--border-color-pv-lime--d59We";
export const colorPvLightLime = "questionnaire-transition-page-module--color-pv-light-lime--2D-4I";
export const bgcolorPvLightLime = "questionnaire-transition-page-module--bgcolor-pv-light-lime--3xq7Y";
export const borderColorPvLightLime = "questionnaire-transition-page-module--border-color-pv-light-lime--3L6Rv";
export const colorPvBlue = "questionnaire-transition-page-module--color-pv-blue--1yV_G";
export const bgcolorPvBlue = "questionnaire-transition-page-module--bgcolor-pv-blue--2Xu5X";
export const borderColorPvBlue = "questionnaire-transition-page-module--border-color-pv-blue--3yzmv";
export const colorPvLightBlue = "questionnaire-transition-page-module--color-pv-light-blue--vKqL4";
export const bgcolorPvLightBlue = "questionnaire-transition-page-module--bgcolor-pv-light-blue--1Z9uH";
export const borderColorPvLightBlue = "questionnaire-transition-page-module--border-color-pv-light-blue--3KhWi";
export const colorPvRed = "questionnaire-transition-page-module--color-pv-red--3f9Ny";
export const bgcolorPvRed = "questionnaire-transition-page-module--bgcolor-pv-red--3w2Z2";
export const borderColorPvRed = "questionnaire-transition-page-module--border-color-pv-red--1GuIc";
export const colorPvLightRed = "questionnaire-transition-page-module--color-pv-light-red--fxKV8";
export const bgcolorPvLightRed = "questionnaire-transition-page-module--bgcolor-pv-light-red--9Ykg7";
export const borderColorPvLightRed = "questionnaire-transition-page-module--border-color-pv-light-red--13znf";
export const colorPvSuccess = "questionnaire-transition-page-module--color-pv-success--xSDi8";
export const bgcolorPvSuccess = "questionnaire-transition-page-module--bgcolor-pv-success--34lB7";
export const borderColorPvSuccess = "questionnaire-transition-page-module--border-color-pv-success--1-9YY";
export const colorPvMenstrual = "questionnaire-transition-page-module--color-pv-menstrual--UssKZ";
export const bgcolorPvMenstrual = "questionnaire-transition-page-module--bgcolor-pv-menstrual--qaFKA";
export const borderColorPvMenstrual = "questionnaire-transition-page-module--border-color-pv-menstrual--3vlu8";
export const colorPvFollicular = "questionnaire-transition-page-module--color-pv-follicular--19G6c";
export const bgcolorPvFollicular = "questionnaire-transition-page-module--bgcolor-pv-follicular--Db9Ai";
export const borderColorPvFollicular = "questionnaire-transition-page-module--border-color-pv-follicular--RMh0R";
export const colorPvOvulatory = "questionnaire-transition-page-module--color-pv-ovulatory--iEN3U";
export const bgcolorPvOvulatory = "questionnaire-transition-page-module--bgcolor-pv-ovulatory--1Vc0q";
export const borderColorPvOvulatory = "questionnaire-transition-page-module--border-color-pv-ovulatory--32jtC";
export const colorPvLuteal = "questionnaire-transition-page-module--color-pv-luteal--1oYmM";
export const bgcolorPvLuteal = "questionnaire-transition-page-module--bgcolor-pv-luteal--17Iuu";
export const borderColorPvLuteal = "questionnaire-transition-page-module--border-color-pv-luteal--2soAP";
export const colorPvPnfBg = "questionnaire-transition-page-module--color-pv-pnf-bg--1W9n-";
export const bgcolorPvPnfBg = "questionnaire-transition-page-module--bgcolor-pv-pnf-bg--3GusL";
export const borderColorPvPnfBg = "questionnaire-transition-page-module--border-color-pv-pnf-bg--3EVME";
export const colorPvPnfIntroBg = "questionnaire-transition-page-module--color-pv-pnf-intro-bg--2T_Qi";
export const bgcolorPvPnfIntroBg = "questionnaire-transition-page-module--bgcolor-pv-pnf-intro-bg--lmRic";
export const borderColorPvPnfIntroBg = "questionnaire-transition-page-module--border-color-pv-pnf-intro-bg--2_U-g";
export const transitionPage = "questionnaire-transition-page-module--transition-page--2BWje";